.section-6-cards {
    width: 396px;
    height: 269px;
    border-radius: 10px;
    border: 14px solid #EDEDED;
    outline: 1px solid transparent;
    position: relative;
    &:hover {
        outline-color: black;
    }
    &-image {
        position: relative;
        z-index: 1;
        border-radius: 0px;
        padding: 64px 0px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.5);
            z-index: 2;
            width: 100%;
            height: 100%;
        }
        h1 {
            font-size: 38px;
            line-height: 120%;
            position: relative;
            z-index: 3;
        }
        button {
            position: relative;
            z-index: 3;
            padding: 16px 32px;
            color: #fff;
            border-color: #fff;
            background-color: transparent;
            &:hover {
                background-color: #fff;
                color: #000;
                border-color: #000;
            } 
        }
    }
}