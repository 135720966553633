.homepage-secton2 {
    &-title {
        font-size: 55px;
        font-weight: bold;
        letter-spacing: 2%;
        line-height: 120%;
        color: #fff;
    }
    &-side {
        margin-left: 22px;
        &-title{
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 2%;
            line-height: 120%;
            color: #fff;
        }
        &-des{
            font-size: 18px;
            letter-spacing: 2%;
            line-height: 120%;
            color: #8E8E8E;
        }
    }
}