.homepage {
    &-section {
        &-1 {
            position: relative;
            &-top-banner {
                width: 100%;
            }
            &-top-banner-bottom {
                position: absolute;
                left: 33%;
                bottom: -62px;
                z-index: 1;
            }
            &-text {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                padding-top: 105px;
                padding-left: 303px;
                h1 {
                    line-height: 120%;
                    font-size: 65px;
                    font-weight: bold;
                    color: #fff;
                }
                button {
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 120%;
                    background-color: #fff;
                    padding: 22px 50px;
                    margin-top: 47px;
                    &:hover {
                        background-color: #1D6E65;
                        color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
        &-2 {
            height: 154px;
            background-color: #242424;
            position: relative;
            z-index: 2;
        }
        &-3 {
            height: 170px;
            background-color: #F6F6F6;
            padding-left: 300px;
            &-title {
                font-size: 50px;
                b {
                    line-height: 120%;
                }
            }
            &-rectangle {
                margin-right: 31px;
            }
        }
        &-4 {
            padding-top: 120px;
            padding-bottom: 120px;
            &-left{
                margin-right: 70px;
                img {
                    width: 56px;
                    height: 78px;
                    margin-bottom: 40px;
                }
                h1 {
                    font-size: 50px;
                    line-height: 120%;
                }
                p {
                    font-size: 20px;
                    letter-spacing: 2%;
                    line-height: 160%;
                }
            }
            &-right{
                &-image-container {
                    width: fit-content;
                    border-radius: 20px;
                    box-shadow: inset 30px 0px 60px #171717;
                    z-index: 2;
                }
                &-video-image {
                    position: relative;
                    z-index: 1;
                }
                &-play-btn {
                    left: 318px;
                    top: 212px;
                    z-index: 3;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        &-5 {
            padding: 70px 0px;
            height: 538px;
            width: 100%;
            background-image: url('../../public/assets/section-5-background.png');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            img {
                margin-top: 66px;
                width: 50px;
                height: 70px;
            }
            h1 {
                font-size: 50px;
                line-height: 120%;
                margin-top: 30px;
            }
            p {
                font-size: 20px;
                letter-spacing: 2%;
                line-height: 160%;
                margin-top: 20px;
            }
            &-btn-container {
                margin-top: 52px;
                margin-bottom: 100px;
                &-btn-1, &-btn-2 {
                    font-size: 20px;
                    line-height: 120%;
                    padding: 20px 40px;
                }
                &-btn-1 {
                    color: #fff;
                    background-color: #1D6E65;
                    &:hover {
                        color: #000;
                        background-color: #fff;
                        border-color: #000;
                    }
                }
                &-btn-2 {
                    color: #000;
                    background-color: #fff;
                    border-color: #242424;
                    &:hover {
                        background-color: #1D6E65;
                        border-color: #1D6E65;
                        color: #fff;
                    }
                }
            }
        }
        &-6 {
            padding: 90px 190px;
            &-title {
                margin-bottom: 64px;
            }
            &-view-all {
                margin-top: 64px;
                padding: 20px 40px;
                border-color: #171717;
                &:hover {
                    border-color: #1D6E65;
                    background-color: #1D6E65;
                    color: #fff;
                }
            }
        }
    }
}